/**
 * Site header
 */
.site-header {
  margin-top: 5px;
  border-bottom: 1px solid $grey-color-light;
  min-height: calc($spacing-unit * 1.865);

  // Positioning context for the mobile navigation icon
  position: relative;
}

div.site-title {
  @include relative-font-size(2.5);
  font-weight: 300;
  letter-spacing: -1px;
  padding-bottom: 8px;

  p {
    margin: 0;
	padding: 0;
  }

  a {
    margin: 0;
    padding: 0;
    &,
    &:visited {
      color: $grey-color-dark;
    }
  }
}

@include media-query($on-palm) {
  div.site-title {
    @include relative-font-size(1.5);
  }
}

div.site-description {
	float: right;
    font-size: $small-font-size;
    color: $grey-color;
	padding-top: $base-font-size - $small-font-size;

	p {
		margin: 0;
		padding: 0;
	}
}

@include media-query($on-palm) {
  div.site-description {
    float: none;
  }
}

nav.site-nav {
  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: calc($spacing-unit / 2);
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
      margin-left: 20px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc($spacing-unit / 2);
}

.contact-list {
  list-style: none;
  margin: 0;
  text-align: right;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin: 0;
  @extend %clearfix;
}

.footer-col {
  margin-bottom: calc($spacing-unit / 2);
}

.footer-col-1 {
  float: left;
  width: -webkit-calc(60% - (#{$spacing-unit} / 2));
  width:         calc(60% - (#{$spacing-unit} / 2));

  h2 {
	  margin: 0;
	  color: $grey-color-dark;
  }
}

.footer-col-2 {
  float: right;
  width: -webkit-calc(40% - (#{$spacing-unit} / 2));
  width:         calc(40% - (#{$spacing-unit} / 2));
  padding-top: 4px;
}

@include media-query($on-palm) {
  .footer-col {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }

  .footer-col-1, .footer-col-2 {
	  float: none;
  }

	.contact-list {
	  text-align: left;
	}
}

// Float images

img.left, img.right {
	max-width: 30%;
	margin: 0;
}

@include media-query($on-palm) {
	img.left, img.right {
		max-width: 40%;
	}
}

img.left {
	float: left;
	margin-right: calc($spacing-unit / 2);
}

img.right {
	float: right;
	margin-left: calc($spacing-unit / 2);
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }

  .footnotes {
	  margin-top: 16px;
	  border-top: 1px solid $grey-color-light;
	  padding-top: 8px;
	  font-size: $small-font-size;
	  color: $grey-color-dark;
  }
}

div.newerolder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: $small-font-size;

  span.older {
    text-align: left;
  }

  span.newer {
    text-align: right;
  }
}

div.gallery-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: calc($spacing-unit/2);
  column-gap: calc($spacing-unit/2);
  justify-items: center;
  align-items: center;

  @include media-query($on-palm) {
    grid-template-columns: 1fr;
  }

  a {
    border: 2px solid white;
    background: white;
    padding: calc($spacing-unit / 2);
    @include relative-font-size(0.7);
    text-align: center;

    &,
    &:visited,
    &:hover,
    &:active {
      color: $grey-color-dark;
      text-decoration: none;
    }
    &:hover {
      border-color: $grey-color-dark;
    }

    div {
      margin-top: calc($spacing-unit / 4);
    }
  }
}

